import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScrollableBorderedWrapper from "../common/ScrollableBorderedWrapper";
import { base32UuidToDashFormat } from "../utils";
import { Box } from "@mui/material";
import { toTournamentDetails } from "../navPaths";
import BackDropSpinner from "../common/BackDropSpinner";

const ShortUrlRedirectPage: React.FC = () => {
   const { tournamentIdBase32 } = useParams();
   const nav = useNavigate();

   useEffect(() => {
      if (tournamentIdBase32) {
         window.setTimeout(() => {
            nav(toTournamentDetails(base32UuidToDashFormat(tournamentIdBase32)));
            //to give a bit of time before the redirect so the user could go back if needed
         }, 1000);
      }
   }, [nav, tournamentIdBase32]);

   return (
      <ScrollableBorderedWrapper>
         <Box>
            <BackDropSpinner show={true} message={"Locating event..."} />
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default ShortUrlRedirectPage;
