import React, { CSSProperties, useCallback, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Footer from "../Footer";
import YouTube, { YouTubeEvent } from "react-youtube";
import { Options } from "youtube-player/dist/types";
import { SxProps } from "@mui/system";

const LARGE_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX = 500;
const SMALL_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX = 340;

const tutorialsPageRootCss: CSSProperties = {
   left: "0px",
   top: "0px",
   width: "100%",
   height: "100%",
   overflow: "auto",
   display: "flex",
   flexDirection: "column",
   boxSizing: "border-box",
};

const contentWrapperSx: SxProps = {
   position: "relative",
   width: "100%",
   flexGrow: 1,
   backgroundColor: "rgba(0,0,0,0.7)",
   display: "flex",
   flexDirection: { xs: "column", sm: "row" },
   alignItems: "flex-start",
   // justifyContent: { xs: "flex-start", sm: "center" },
   alignContent: { xs: "center", sm: "flex-start" },
   flexWrap: "wrap",
   padding: { xs: "2vmin", sm: "5vmin" },
   gap: { xs: "5vmin", sm: "2vmin" },
};

const youtubeComponentCss: CSSProperties = {
   boxShadow: `0 0 5px 1px rgba(0,0,0,0.5)`,
};

const youtubeOptLargeScreen: Options = {
   width: `${LARGE_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX}px`,
   height: `${(LARGE_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX * 9) / 16}px`,
};

const youtubeOptSmallScreen: Options = {
   width: `${SMALL_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX}px`,
   height: `${(SMALL_SCREEN_YOUTUBE_COMPONENT_WIDTH_IN_PX * 9) / 16}px`,
};

interface ITutorialsPage {}

const TutorialsPage: React.FC<ITutorialsPage> = () => {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const youtubeOpt = useMemo(() => (smallScreen ? youtubeOptSmallScreen : youtubeOptLargeScreen), [smallScreen]);
   const onPlayerReady = useCallback((event: YouTubeEvent) => {
      event.target.pauseVideo();
   }, []);

   return (
      <Box id={"tutorialsPageRoot"} style={tutorialsPageRootCss}>
         <Box sx={contentWrapperSx}>
            <YouTube
               videoId="aaOLc_wpzmQ"
               opts={youtubeOpt}
               onReady={onPlayerReady}
               style={youtubeComponentCss}
               loading={"lazy"}
            />
            <YouTube
               videoId="t627kgeyUhY"
               opts={youtubeOpt}
               onReady={onPlayerReady}
               style={youtubeComponentCss}
               loading={"lazy"}
            />
         </Box>
         <Footer />
      </Box>
   );
};

export default TutorialsPage;
