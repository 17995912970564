import React, { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Button,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import { useTournamentApi } from "../../../rest.client/useTournamentApi";
import ResultsTierList from "./ResultsTierList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackDropSpinner from "../../../common/BackDropSpinner";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CustomIconButton from "../../../common/CustomIconButton";

export default function EditTournamentResultsExpander({
   open,
   remoteTournament,
}: {
   open: boolean;
   remoteTournament: RemoteTournament;
}) {
   const {
      createPrizeTier: { call: createTier },
      deleteAllPrizeTiers: { call: deleteAll },
      autoGeneratePrizeTiers: { call: autoGenerate },
      loading,
   } = useTournamentApi();
   const theme = useTheme();
   const [expanded, setExpanded] = useState(open);

   const [tierName, setTierName] = useState("");
   const tiers = remoteTournament.tournament?.prizeTiers || [];

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <EmojiEventsIcon />
               <Typography>Edit tournament results</Typography>
               {tiers.length === 0 && <Alert severity={"warning"}>No results added!</Alert>}
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <BackDropSpinner show={loading} />
            {remoteTournament.canEdit && (
               <Box sx={{ marginBottom: theme.spacing(2), display: "flex", gap: theme.spacing(1) }}>
                  <CustomIconButton
                     icon={<PrecisionManufacturingIcon />}
                     buttonProps={{
                        variant: "contained",
                        disabled: tiers.length > 0,
                     }}
                     onClick={async () => {
                        remoteTournament.id && (await autoGenerate({ pathParams: { id: remoteTournament.id } }));
                        remoteTournament.loadData();
                     }}
                  >
                     Auto generate
                  </CustomIconButton>
                  <CustomIconButton
                     buttonProps={{
                        disabled: tiers.length === 0,
                     }}
                     icon={<DeleteForeverIcon fontSize={"small"} />}
                     onClick={async () => {
                        remoteTournament.id && (await deleteAll({ pathParams: { id: remoteTournament.id } }));
                        remoteTournament.loadData();
                     }}
                  >
                     Delete all
                  </CustomIconButton>
               </Box>
            )}
            {remoteTournament.isOrganiser && (
               <Box sx={{ display: "flex" }}>
                  <TextField
                     fullWidth
                     required
                     size={"small"}
                     variant={"outlined"}
                     label={"Tier name"}
                     onChange={(ev) => setTierName(ev.target.value)}
                     value={tierName}
                     inputProps={{ maxLength: 100 }}
                  />
                  <Button
                     disabled={tierName === ""}
                     onClick={async () => {
                        remoteTournament.tournament &&
                           (await createTier({
                              pathParams: { id: remoteTournament.tournament.id },
                              body: { name: tierName },
                           }));
                        remoteTournament.loadData();
                        setTierName("");
                     }}
                  >
                     Create
                  </Button>
               </Box>
            )}
            <ResultsTierList remoteTournament={remoteTournament} />
         </AccordionDetails>
      </Accordion>
   );
}
