import React, { useEffect } from "react";
import { useApplicationApi } from "../../rest.client/useApplicationApi";
import { Box, CircularProgress, Paper } from "@mui/material";
import InsightsWinsBySeat from "../InsightsWinsBySeat";
import PieChartInsightsView from "../PieChartInsightsView";
import MetaInsightsWinsByTurn from "./MetaInsightsWinsByTurn";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import InsightsSelectorBanner from "../InsightsSelectorBanner";

export default function MetaInsightsPage() {
   const {
      findMetaInsights: { call: findMetaInsights, responseData: insights, loading },
   } = useApplicationApi();

   useEffect(() => {
      findMetaInsights();
   }, [findMetaInsights]);

   return (
      <ScrollableBorderedWrapper>
         <InsightsSelectorBanner />
         <Paper>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
               {loading && (
                  <Box sx={{}}>
                     <CircularProgress />
                  </Box>
               )}
               {!loading && insights != null && (
                  <PieChartInsightsView winCons={insights.winConsUsed} highImpact={insights.highImpactCards} />
               )}
            </Box>
            {!loading && insights != null && (
               <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                  <MetaInsightsWinsByTurn data={insights.gamesByTurn} />
                  <InsightsWinsBySeat data={insights.gamesBySeat} />
               </Box>
            )}
         </Paper>
      </ScrollableBorderedWrapper>
   );
}
