import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormLabel,
   TextField,
   Theme,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import DeckViewer from "../deckViewer/DeckViewer";
import MoxfieldDecklistTextField from "../deckViewer/MoxfieldDecklistTextField";
import CardPlaceHolderComponent from "../../gameFeatures/playerAndCommander/CardPlaceHolderComponent";
import useRemoteRegistration from "./useRemoteRegistration";
import { RemoteTournament } from "../useRemoteTournament";
import { ComponentMode } from "../../UI_CONST";
import { empty } from "../../utils";
import { DecklistSourceType, SnapshotSummary } from "../../rest.client/useDeckListApi";
import RegistrationStatusBanner from "./RegistrationStatusBanner";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import { XLARGE_PADDING_IN_PX } from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import DecklistInfoCard from "./DecklistInfoCard";
import useReload from "../../header/useReload";
import { SxProps } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";
import DecklistSourceSelector from "../deckViewer/DecklistSourceSelector";
import TextDecklistCreator from "../deckViewer/TextDecklistCreator";
import { RegistrationSummary, RegistrationType } from "../../rest.client/useTournamentApi";
import { Maybe } from "../../TYPE";

interface ITournamentRegistration {
   open: boolean;
   remoteTournament: RemoteTournament;
   registrationType: RegistrationType;
   targetRegistration?: Maybe<RegistrationSummary>;
   closeHandler: () => void;
}

const warningTextSx: SxProps<Theme> = {
   marginRight: 1,
   color: (theme) => theme.palette.warning.main,
};

const TournamentRegistrationDialog: React.FC<ITournamentRegistration> = ({
   open,
   targetRegistration,
   registrationType,
   closeHandler,
   remoteTournament,
}) => {
   const remoteRegistration = useRemoteRegistration(remoteTournament, targetRegistration, registrationType);
   const loadData = remoteRegistration.loadData;
   const theme = useTheme();
   const addMessageItem = useAddMessageItem();
   const reload = useReload("registration");
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const [showDeckViewer, setShowDeckViewer] = useState<boolean>(false);
   const pair = remoteRegistration.commanderPair;
   const commander1 = remoteRegistration.commanderPair?.commander1;
   const commander2 = remoteRegistration.commanderPair?.commander2;
   const decklistLocked = Boolean(remoteRegistration.registrationFull?.decklistLocked);
   const [decklistSource, setDecklistSource] = useState<DecklistSourceType>("MOXFIELD");
   const existingSourceType = remoteRegistration?.snap?.sourceType;
   const requiresPassword =
      registrationType !== "THIRD_PARTY_DIRECT" &&
      !remoteTournament.tournament?.userInvited &&
      !remoteTournament.isOrganiser &&
      remoteTournament.tournament?.registrationMode === "PASSWORD" &&
      remoteRegistration.registrationFull == null;

   const showSecondaryCommanderPicker: boolean = Boolean(
      pair?.commander1.partner || pair?.commander1.requiresBackground || pair?.commander1.friendsForever
   );

   useEffect(() => {
      if (open) {
         loadData();
      }
   }, [loadData, open]);

   useEffect(() => {
      if (existingSourceType) {
         setDecklistSource(existingSourceType);
      }
   }, [existingSourceType]);

   const onSnapUpdate = useCallback((snap: SnapshotSummary) => remoteRegistration.setSnap(snap), [remoteRegistration]);

   const disableRegisterBtn = useMemo(
      () =>
         !remoteRegistration.valid ||
         remoteTournament.tournament?.status === "ENDED" ||
         (remoteRegistration.requiresDirectUserName && remoteRegistration.directUserNameInvalid),
      [
         remoteRegistration.directUserNameInvalid,
         remoteRegistration.requiresDirectUserName,
         remoteRegistration.valid,
         remoteTournament.tournament?.status,
      ]
   );

   const warningText = useMemo(() => {
      if (remoteRegistration.requiresDirectUserName && remoteRegistration.directUserNameInvalid) {
         return "User name is invalid, it cannot be blank and must be at least 3 characters long.";
      } else if (!remoteRegistration.valid) {
         return "You need to select your commander(s)";
      } else if (remoteTournament.hasEnded) {
         return "Tournament has ended.";
      }
   }, [
      remoteRegistration.directUserNameInvalid,
      remoteRegistration.requiresDirectUserName,
      remoteRegistration.valid,
      remoteTournament.hasEnded,
   ]);

   return (
      <Dialog
         id={"tournamentRegistrationDrawer"}
         open={open}
         fullScreen={smallScreen}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"md"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               closeHandler();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Registration</DialogTitle>
         <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
               {remoteRegistration.activeRegistrationSummary != null && (
                  <RegistrationStatusBanner registration={remoteRegistration.activeRegistrationSummary} />
               )}
               {remoteRegistration.registrationFull?.decklistLocked && (
                  <Alert severity={"warning"} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                     Decklist and commander selection are locked. If you need to make changes please contact the
                     tournament organisers.
                  </Alert>
               )}
               <Box
                  id={"tournamentRegistrationContent"}
                  sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2), paddingTop: 5 }}
               >
                  {remoteRegistration.requiresDirectUserName && (
                     <Box sx={{ marginBottom: theme.spacing(1) }}>
                        <TextField
                           fullWidth
                           size={"small"}
                           variant={"outlined"}
                           label={"Guest user name"}
                           value={remoteRegistration.directUserName || ""}
                           inputProps={{ maxLength: 2000 }}
                           error={remoteRegistration.directUserNameInvalid}
                           onChange={(e) => remoteRegistration.setDirectUserName(e.target.value)}
                        />
                     </Box>
                  )}
                  <Box
                     sx={{
                        display: "flex",
                        gap: `${XLARGE_PADDING_IN_PX}px`,
                        justifyContent: "center",
                        flexWrap: "wrap",
                     }}
                  >
                     <CardPlaceHolderComponent
                        card={remoteRegistration.commanderPair?.commander1}
                        setCard={(card) =>
                           remoteRegistration.setCommanderPair({
                              commander1: card,
                              commander2: null,
                           })
                        }
                        disallowedCards={[]}
                        width={200}
                        position={"initial"}
                        left={0}
                        right={0}
                        top={0}
                        bottom={0}
                        hoverText={"1st commander"}
                        defaultText={"Add commander"}
                        filterCommanders={true}
                        filterPartners={false}
                        filterBackgrounds={false}
                        filterFriendsForever={false}
                        mode={decklistLocked ? ComponentMode.VIEW : ComponentMode.EDIT}
                        key={"commander1"}
                        cardWidthUnit={"px"}
                        onClick={empty}
                     />
                     {showSecondaryCommanderPicker ? (
                        <CardPlaceHolderComponent
                           card={commander2}
                           setCard={(c) =>
                              remoteRegistration.commanderPair &&
                              remoteRegistration.setCommanderPair({
                                 commander1: remoteRegistration.commanderPair.commander1,
                                 commander2: c,
                              })
                           }
                           disallowedCards={[]}
                           width={200}
                           position={"initial"}
                           left={0.15}
                           right={0.15}
                           top={0.15}
                           bottom={0.15}
                           hoverText={"2nd commander"}
                           defaultText={"Add 2nd commander"}
                           hoverTextPlacement={"top"}
                           filterCommanders={!commander1?.requiresBackground}
                           filterPartners={!commander1?.requiresBackground}
                           filterBackgrounds={Boolean(commander1?.requiresBackground)}
                           filterFriendsForever={Boolean(commander1?.friendsForever)}
                           mode={decklistLocked ? ComponentMode.VIEW : ComponentMode.EDIT}
                           key={"commander2"}
                           cardWidthUnit={"px"}
                           onClick={empty}
                        />
                     ) : null}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
                     <FormLabel component="legend">Decklist selection:</FormLabel>
                     <DecklistSourceSelector
                        type={decklistSource}
                        setType={setDecklistSource}
                        disabled={decklistLocked}
                     />
                     {decklistSource === "MOXFIELD" && (
                        <MoxfieldDecklistTextField
                           onSnapshotUpdate={onSnapUpdate}
                           snapshot={remoteRegistration.snap}
                           decklistLocked={decklistLocked}
                           required={false}
                        />
                     )}
                     {decklistSource === "TEXT" && (
                        <TextDecklistCreator
                           onSnapshotUpdate={onSnapUpdate}
                           snapshot={remoteRegistration.snap}
                           decklistLocked={decklistLocked}
                        />
                     )}
                     <Box>
                        {remoteRegistration.snap && (
                           <DecklistInfoCard
                              snapshot={remoteRegistration.snap}
                              registration={remoteRegistration.registrationFull}
                           />
                        )}
                     </Box>
                  </Box>
               </Box>
               <DeckViewer
                  open={showDeckViewer}
                  initialSnapId={remoteRegistration.snap?.id}
                  closeHandler={() => setShowDeckViewer(false)}
               />
               <Box>
                  <FormLabel component="legend">Additional info:</FormLabel>
                  <TextField
                     fullWidth
                     multiline
                     rows={5}
                     size={"small"}
                     variant={"outlined"}
                     label={"Additional info for organiser"}
                     value={remoteRegistration.additionalDetails || ""}
                     inputProps={{ maxLength: 2000 }}
                     onChange={(e) => remoteRegistration.setAdditionalDetails(e.target.value)}
                  />
               </Box>
               {requiresPassword && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
                     <FormLabel component="legend">Password:</FormLabel>
                     {remoteRegistration.password == null && (
                        <Alert severity={"warning"}>Password required to register for this tournament!</Alert>
                     )}
                     <TextField
                        fullWidth
                        type={"password"}
                        size={"small"}
                        variant={"outlined"}
                        label={"Password"}
                        value={remoteRegistration.password}
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) => remoteRegistration.setPassword(e.target.value)}
                     />
                  </Box>
               )}
            </Box>
            <DialogActions>
               {disableRegisterBtn && <WarningIcon sx={warningTextSx} />}
               {disableRegisterBtn && <Typography sx={warningTextSx}>{warningText}</Typography>}
               <Button
                  variant={"contained"}
                  onClick={async () => {
                     await remoteRegistration.save();
                     addMessageItem(`Registration has been updated!`, "success", 3000);
                     await remoteTournament.loadData();
                     reload.requestReload();
                     closeHandler();
                  }}
                  disabled={disableRegisterBtn}
               >
                  {remoteRegistration.activeRegistrationSummary != null ? "Update" : "Register"}
               </Button>
               <Button variant={"outlined"} onClick={closeHandler}>
                  Cancel
               </Button>
            </DialogActions>
         </DialogContent>
      </Dialog>
   );
};

export default TournamentRegistrationDialog;
