import React, { useEffect } from "react";
import { Box } from "@mui/material";
import TournamentDetails from "./TournamentDetails";
import { useParams } from "react-router-dom";
import StageTabs from "./StageTabs";
import StageView from "./stageView/StageView";
import useRemoteTournament from "../useRemoteTournament";
import useReload from "../../header/useReload";
import BackDropSpinner from "../../common/BackDropSpinner";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import RulesInfractionsExpander from "../rulesInfractions/RulesInfractionsExpander";
import ViewTournamentResultsExpander from "./prizeTiers/ViewTournamentResultsExpander";

const ViewTournamentPage: React.FC = () => {
   const { tournamentId, stageId } = useParams();
   const { reload } = useReload();
   const showStage = stageId != null;
   const remoteTournament = useRemoteTournament(tournamentId);
   const showResultsView =
      !showStage &&
      remoteTournament.tournament?.canAccessData &&
      remoteTournament.tournament?.prizeTiers != null &&
      remoteTournament.tournament.prizeTiers.length > 0;

   useEffect(() => {
      if (reload) {
         remoteTournament.loadData();
      }
   }, [reload, remoteTournament]);

   return (
      <ScrollableBorderedWrapper>
         <BackDropSpinner show={remoteTournament.loading} />
         <Box
            id={"leagueViewRoot"}
            sx={{
               width: "100%",
               display: "flex",
               flexDirection: "column",
               justifyContent: "flex-start",
               alignItems: "center",
            }}
         >
            <Box sx={{ width: "100%" }}>
               <StageTabs remoteTournament={remoteTournament} stageId={stageId} />
               {showResultsView && (
                  <ViewTournamentResultsExpander
                     remoteTournament={remoteTournament}
                     defaultOpened={remoteTournament.hasEnded}
                  />
               )}
               {!showStage && <TournamentDetails remoteTournament={remoteTournament} />}
            </Box>
            {showStage && <StageView remoteTournament={remoteTournament} />}
            {!showStage && remoteTournament.isOrganiser && (
               <RulesInfractionsExpander remoteTournament={remoteTournament} />
            )}
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default ViewTournamentPage;
