import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { useApplicationApi, UserSummary } from "../../rest.client/useApplicationApi";
import { Maybe, TPlayerIndex } from "../../TYPE";
import { useSetPlayerAndDefaultCommander } from "../../customHooks/customHooks";
import { MutableGame } from "../MutableGame";

const logGamePagePlayerPickerSx: SxProps = {
   left: "0",
   top: "0",
   position: "absolute",
   width: "100%",
};

export const ANONYMOUS_USER_ID = "E1Ht8efkzyO1GcwbedHmwGvnHXB3";

const anonymousUser: UserSummary = {
   id: ANONYMOUS_USER_ID,
   name: "Anonymous",
   userSource: "IDENTITY_PLATFORM",
   shortTag: "anon",
};

interface ILogGamePagePlayerPicker {
   playerIndex: TPlayerIndex;
   game: MutableGame;
}

const LogGamePagePlayerPicker: React.FC<ILogGamePagePlayerPicker> = ({ playerIndex, game }) => {
   const {
      findUserByNameStart: { call: findUsers, responseData },
   } = useApplicationApi();
   const {
      findUserDefaultCommanderById: { call: findDefaultCommanders },
   } = useApplicationApi();

   const [currentPlayerUserSummary, setCurrentPlayerUserSummary] =
      playerIndex === 1
         ? [game.player1Summary, game.setPlayer1Summary]
         : playerIndex === 2
         ? [game.player2Summary, game.setPlayer2Summary]
         : playerIndex === 3
         ? [game.player3Summary, game.setPlayer3Summary]
         : [game.player4Summary, game.setPlayer4Summary];

   const [currentPlayerCommanderPair, setCurrentPlayerCommanderPair] =
      playerIndex === 1
         ? [game.player1CommanderPair, game.setPlayer1CommanderPair]
         : playerIndex === 2
         ? [game.player2CommanderPair, game.setPlayer2CommanderPair]
         : playerIndex === 3
         ? [game.player3CommanderPair, game.setPlayer3CommanderPair]
         : [game.player4CommanderPair, game.setPlayer4CommanderPair];

   const player1UserSummary = game.player1Summary;
   const player2UserSummary = game.player2Summary;
   const player3UserSummary = game.player3Summary;
   const player4UserSummary = game.player4Summary;

   // TODO: Check if this still works
   const setPlayerAndDefaultCommander = useSetPlayerAndDefaultCommander(
      findDefaultCommanders,
      currentPlayerCommanderPair,
      setCurrentPlayerCommanderPair,
      setCurrentPlayerUserSummary
   );

   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [processingInput, setProcessingInput] = useState(false);

   const otherPlayersUserSummaries = useMemo(() => {
      const result: Array<UserSummary> = [];
      player1UserSummary && player1UserSummary.id !== currentPlayerUserSummary?.id && result.push(player1UserSummary);
      player2UserSummary && player2UserSummary.id !== currentPlayerUserSummary?.id && result.push(player2UserSummary);
      player3UserSummary && player3UserSummary.id !== currentPlayerUserSummary?.id && result.push(player3UserSummary);
      player4UserSummary && player4UserSummary.id !== currentPlayerUserSummary?.id && result.push(player4UserSummary);
      return result;
   }, [currentPlayerUserSummary?.id, player1UserSummary, player2UserSummary, player3UserSummary, player4UserSummary]);

   const userSearchHandler = useCallback(
      (ev: SyntheticEvent, value: string) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         if (value.length >= 2) {
            setProcessingInput(true);
            const timeOutId = window.setTimeout(() => {
               findUsers({ queryParams: { name: value, tag: value } }).finally(() => {
                  setProcessingInput(false);
               });
            }, 500);
            setSearchActiveTimeout(timeOutId);
         }
      },
      [searchActiveTimeout, findUsers]
   );

   const usersToShow: Array<UserSummary> = useMemo(
      () =>
         responseData != null
            ? responseData._embedded.users.filter(
                 (u) => otherPlayersUserSummaries.find((oP) => oP && oP.id === u.id) == null
              )
            : [],
      [otherPlayersUserSummaries, responseData]
   );

   const onChange = useCallback(
      (event: SyntheticEvent, val: Maybe<UserSummary>) => {
         if (val) setPlayerAndDefaultCommander(val);
         else {
            setCurrentPlayerCommanderPair(null);
            setCurrentPlayerUserSummary(null);
         }
      },
      [setCurrentPlayerCommanderPair, setCurrentPlayerUserSummary, setPlayerAndDefaultCommander]
   );

   const emptyResult = useMemo(
      () => (
         <div style={{ cursor: "pointer" }} onClick={() => setPlayerAndDefaultCommander(anonymousUser)}>
            Set anonymous
         </div>
      ),
      [setPlayerAndDefaultCommander]
   );

   return (
      <Autocomplete
         forcePopupIcon={false}
         size={"small"}
         onInputChange={userSearchHandler}
         onChange={onChange}
         options={usersToShow}
         value={currentPlayerUserSummary}
         getOptionLabel={(option) => option.name + "/" + option.shortTag}
         isOptionEqualToValue={(option, value) => option.id === value.id}
         loading={processingInput}
         noOptionsText={emptyResult}
         sx={logGamePagePlayerPickerSx}
         clearOnBlur={true}
         renderInput={(params) => <TextField label={`Player ${playerIndex}`} {...params} />}
      />
   );
};

export default LogGamePagePlayerPicker;
