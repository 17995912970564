import { useMemo } from "react";
import { EmbeddedResponse, PageableQueryParams, PageableResponse, UserSummary } from "./useApplicationApi";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { CardExtendedInfo } from "../cardFeatures/CardTypes";
import { SnapshotSummary } from "./useDeckListApi";
import { EMPTY_STRING } from "../UI_CONST";
import dayjs from "dayjs";
import { StageSummaryResponse, StandingsRegResponse } from "./useStagesApi";
import { AxiosRequestConfig } from "axios";
import { Maybe } from "../TYPE";
import { TagSummary } from "./useTagsApi";

export function useTournamentApi() {
   const instance = useAxiosInstance();

   const api = {
      createTournament: useRequest<TournamentSummary, never, SaveTournamentRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      createTestTournament: useRequest<TournamentSummary, never, CreateTestTournamentRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/createTestTournament",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateTournament: useRequest<TournamentSummary, { id: string }, SaveTournamentRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/update/{id}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      getStandings: useRequest<TournamentStandingsResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/standings",
               method: "get",
            }),
            []
         ),
         instance
      ),
      endTournament: useRequest<TournamentSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/end/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      startTournament: useRequest<TournamentSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/start/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      inviteToTournament: useRequest<TournamentFull, { id: string }, InviteRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/invite/{id}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      unInviteToTournament: useRequest<TournamentFull, { id: string; uid: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/invite/{id}/{uid}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      registerForTournament: useRequest<RegistrationSummary, never, RegisterForTournamentRequest, never>(
         useMemo(
            () => ({
               url: "/registrations/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateRegistration: useRequest<RegistrationSummary, { id: string }, UpdateRegistrationRequest, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      acceptRegistration: useRequest<RegistrationSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/accept/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      rejectRegistration: useRequest<RegistrationSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/reject/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      dropRegistration: useRequest<RegistrationSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/drop",
               method: "post",
            }),
            []
         ),
         instance
      ),
      lockDecklist: useRequest<RegistrationSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/decklist/lock",
               method: "post",
            }),
            []
         ),
         instance
      ),
      unlockDecklist: useRequest<RegistrationSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/decklist/unlock",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addTag: useRequest<RegistrationSummary, { id: string; tagId: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/tags/{tagId}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      removeTag: useRequest<RegistrationSummary, { id: string; tagId: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}/tags/{tagId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      getRegistrationFullById: useRequest<RegistrationFull, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/registrations/{id}?projection=full",
               method: "get",
               configMutator: disableMatchCacheMutator,
            }),
            []
         ),
         instance
      ),
      getAllTournaments: useRequest<TournamentSummariesResponse, never, never, TournamentSearchQueryParams>(
         useMemo(
            () => ({
               url: "/tournaments/search/byFilter/",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  size: 10,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      searchTournamentsByUserIdAndText: useRequest<
         TournamentSummariesResponse,
         never,
         never,
         TournamentSearchByIdAndTextQueryParams
      >(
         useMemo(
            () => ({
               url: "/tournaments/search/byUserId/",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  userId: "",
                  size: 25,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      getTournamentFullById: useRequest<TournamentFull, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}?projection=full",
               method: "get",
               configMutator: disableMatchCacheMutator,
            }),
            []
         ),
         instance
      ),
      getTagsInTournament: useRequest<EmbeddedResponse<{ tags: TagSummary[] }>, { id: string }, never, never>(
         useMemo(
            () => ({
               url: '/tags/search/byTournamentId?tournamentId={id}&projection=summary"',
               method: "get",
            }),
            []
         ),
         instance
      ),
      getTournamentSummaryById: useRequest<TournamentSummary, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}?projection=summary",
               method: "get",
               configMutator: disableMatchCacheMutator,
            }),
            []
         ),
         instance
      ),
      getRegistrationsByTournamentId: useRequest<
         PageableResponse<{ registrations: RegistrationSummaryWithTags[] }>,
         never,
         never,
         RegistrationSearchQueryParams
      >(
         useMemo(
            () => ({
               url: "/registrations/search/byTournamentId?projection=withTags",
               method: "get",
               queryParamsDefault: {
                  size: 25,
                  page: 0,
                  tournamentId: "",
                  sort: "createDate,desc",
               },
            }),
            []
         ),
         instance
      ),
      uploadLogo: useRequest<never, { id: string }, FormData, never>(
         useMemo(
            () => ({
               url: "/tournaments/logo/{id}",
               method: "post",
               configMutator: (baseConfig) => {
                  if (!baseConfig.headers) {
                     baseConfig.headers = {};
                  }
                  baseConfig.headers["Content-Type"] = "multipart/form-data";
                  return baseConfig;
               },
            }),
            []
         ),
         instance
      ),
      createPrizeTier: useRequest<PrizeTierFullResp, { id: string }, SavePrizeTierRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updatePrizeTier: useRequest<PrizeTierFullResp, { id: string; tierNum: number }, SavePrizeTierRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/{tierNum}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deletePrizeTier: useRequest<never, { id: string; tierNumber: number }, SavePrizeTierRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/{tierNumber}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      deleteAllPrizeTiers: useRequest<never, { id: string }, SavePrizeTierRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      autoGeneratePrizeTiers: useRequest<TournamentSummary, { id: string }, SavePrizeTierRequest, never>(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/autoGenerate",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addWinner: useRequest<
         PrizeTierFullResp,
         {
            id: string;
            tierNumber: number;
            registrationId: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/{tierNumber}/winners/{registrationId}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      removeWinner: useRequest<
         PrizeTierFullResp,
         {
            id: string;
            tierNumber: number;
            registrationId: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/tournaments/{id}/prizeTiers/{tierNumber}/winners/{registrationId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export const disableMatchCacheMutator = (config: AxiosRequestConfig) => {
   if (config.headers == null) {
      config.headers = {};
   }
   //hack to disable cache
   config.headers["If-None-Match"] = "*";
   return config;
};

export interface TournamentSearchQueryParams extends PageableQueryParams {
   joinedUserId?: Maybe<string>;
   organiserUserId?: Maybe<string>;
   searchText?: Maybe<string>;
   status?: Maybe<TournamentStatus>;
   afterDate?: Maybe<string>;
   projection: string;
}

export interface TournamentSearchByIdAndTextQueryParams extends PageableQueryParams {
   userId: string;
   searchText?: Maybe<string>;
   projection: string;
}

export interface Auditable {
   createDate: string;
   modifyDate: string;
}

export interface RegistrationSearchQueryParams extends PageableQueryParams {
   tournamentId: string;
}

export interface TournamentSummary extends Auditable {
   id: string;
   status: TournamentStatus;
   creator: UserSummary;
   description: string;
   isPublic: boolean;
   autoAcceptRegistrations: boolean;
   maxPlayerCount: number;
   areDecklistsVisible: boolean;
   logoUrl: Maybe<string>;
   location: string;
   name: string;
   inviteOnly: boolean;
   startDate: string;
   endDate?: string;
   activeRegistrationCount: number;
   registrationMode: RegistrationMode;
}

export type RegistrationMode = "OPEN" | "INVITE" | "PASSWORD";

export interface TournamentFull extends TournamentSummary {
   tournamentOrganisers: UserSummary[];
   invitedUsers: UserSummary[];
   registrations: RegistrationSummary[];
   stages: StageSummaryResponse[];
   myRegistration?: RegistrationSummary;
   canRegister: boolean;
   hasRegistered: boolean;
   canAccessData: boolean;
   userInvited: boolean;
   userRegistrationAccepted: boolean;
   prizeTiers: PrizeTierFullResp[];
   base32Id: string;
}

export interface SaveTournamentRequest {
   description: string;
   startDate: Date;
   endDate?: Date;
   maxPlayerCount: number;
   registrationMode: RegistrationMode;
   password?: string;
   isPublic: boolean;
   autoAcceptRegistrations: boolean;
   makeDecklistsVisible: boolean;
   location: string;
   name: string;
   tournamentOrganisers: string[];
}

export interface CreateTestTournamentRequest {
   name: string;
   playerCount: number;
}

export const EMPTY_SAVE_TOURNAMENT_REQUEST_DATA: SaveTournamentRequest = {
   description: EMPTY_STRING,
   startDate: new Date(),
   endDate: dayjs().add(1, "day").toDate(),
   maxPlayerCount: 64,
   registrationMode: "OPEN",
   autoAcceptRegistrations: false,
   makeDecklistsVisible: false,
   isPublic: false,
   location: EMPTY_STRING,
   name: EMPTY_STRING,
   tournamentOrganisers: [],
};

export interface RegisterForTournamentRequest extends UpdateRegistrationRequest {
   registrationType: RegistrationType;
   tournamentId: string;
}

export type RegistrationType = "CURRENT_USER" | "THIRD_PARTY_DIRECT";

export interface UpdateRegistrationRequest {
   commander1Id: string;
   commander2Id?: string;
   additionalDetails: Maybe<string>;
   directRegistrationName?: string;
   snapshotId?: string;
   password?: Maybe<string>;
}

export interface RegistrationSummary {
   id: string;
   status: RegistrationStatus;
   commander1: CardExtendedInfo;
   commander2?: CardExtendedInfo;
   decklistLocked: boolean;
   user: UserSummary;
   decklistSnapshotId?: string;
}

export interface TournamentStandingsResponse {
   standings: StandingsRegResponse[];
}

export interface RegistrationSummaryWithTags extends RegistrationSummary {
   tags: TagSummary[];
}

export interface RegistrationFull extends RegistrationSummary, RegistrationSummaryWithTags {
   additionalDetails?: string;
   snapshot?: SnapshotSummary;
}

export interface SavePrizeTierRequest {
   name: string;
}

export interface TournamentWinnerFullResp {
   tier: number;
   registration: RegistrationSummary;
}

export interface PrizeTierFullResp {
   name: string;
   tier: number;
   winners: TournamentWinnerFullResp[];
}

export interface TournamentSummariesResponse extends PageableResponse<{ tournaments: TournamentSummary[] }> {}

export type RegistrationStatus = "ACCEPTED" | "PENDING" | "REJECTED" | "DROPPED";

export interface InviteRequest {
   userId: string;
}

export type TournamentStatus = "SCHEDULED" | "STARTED" | "ENDED";

export function getUserFriendlyNameForRegMode(registrationMode: RegistrationMode) {
   switch (registrationMode) {
      case "PASSWORD":
         return "Password";
      case "INVITE":
         return "Invite only";
      case "OPEN":
         return "Open";
   }
}
